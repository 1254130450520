import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

// React Icons
import { TbError404 } from 'react-icons/tb'

// Components
import Layout from '../components/Layout'

const ErrorContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const ErrorIcon = styled(TbError404)`
  font-size: 12rem;
`

const NotFoundPage = ({ location }) => {
  return (
    <Layout location={location} seo={{ title: 'Error Page' }}>
      <ErrorContainer>
        <ErrorIcon />
        <h1>Page Not Found</h1>
      </ErrorContainer>
    </Layout>
  )
}

NotFoundPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default NotFoundPage
